<!-- 用户列表 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-select v-model:value="field" @change="onFieldChange">
            <a-select-option value="username">用户名</a-select-option>
            <a-select-option value="realname">姓名</a-select-option>
            <a-select-option value="nickname">昵称</a-select-option>
          </a-select>
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
            @change.stop="onSearchChange"
          />
          <a-button type="primary" @click="onAdvanceSearch">
            <SvgIcon iconName="search" className="svg16" />高级搜索
          </a-button>
        </div>
        <div>
          <a-upload
            class="upload"
            action="/user/user/import"
            :headers="headers"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :disabled="importDisabled"
            accept=".xls,.xlsx"
            @change="uploadChange"
          >
            <a-button type="primary" :disabled="importDisabled">
              <SvgIcon
                :iconName="importBtnIcon"
                className="svg15"
                style="margin-right: 10px"
              />{{ importBtnText }}
            </a-button>
          </a-upload>
          <a-button type="primary" @click="doExportData">
            <SvgIcon
              iconName="export1"
              className="svg15"
              style="margin-right: 10px"
            />导出数据
          </a-button>
          <a-button danger type="primary" @click="doDeleteUsers">
            <DeleteOutlined />批量删除
          </a-button>
          <a-button type="primary" @click.stop="doAddUser">
            <PlusOutlined />添加用户
          </a-button>
        </div>
      </div>
    </div>
    <div v-show="showSearchArea" class="searchAreaCss">
      <SearchArea @initUserList="initUserList" />
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 2100 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="userList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :rowSelection="rowSelection"
      >
        <template #status="{ record }">
          <a-switch
            size="small"
            checkedChildren="正常"
            unCheckedChildren="禁用"
            v-model:checked="record.status"
            @change="doSetStatus(record)"
          />
        </template>
        <template #action="{ record }">
          <span>
            <a-button type="link" size="small" @click="doDeleteUser(record)">
              <DeleteOutlined />删除
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doEditUser(record)">
              <EditOutlined />编辑
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doDetailUser(record)">
              <FileTextOutlined />详情
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <UserForm
        :modalVisible="modalVisible"
        :isCreate="isCreate"
        :searchParam="searchParam"
        :editData="editData"
        @handleModalVisible="handleModalVisible"
        @saveCallBack="saveCallBack"
      />
    </div>
  </div>
</template>

<script>
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import {
  getUserListPage,
  updateUserStatus,
  deleteUser,
  deleteUsers,
  exportUsers,
  //importUsers,
} from "@/api/user";

import moment from "moment";
import UserForm from "./components/UserForm.vue";
import SearchArea from "./components/mySearchArea.vue";
import { message, Modal } from "ant-design-vue";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "User",
  components: {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    FileTextOutlined,
    UserForm,
    SearchArea,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      field: "username",
      keyword: "",
      loading: false,
      isCreate: 0, //0新增  1编辑  2详情
      selectedRowKeys: [],
      importBtnText: "导入数据",
      importBtnIcon: "import1",
      importDisabled: false,
      showSearchArea: false,
      headers: {
        authorization: window.sessionStorage.getItem("token"),
      },
      editData: {
        id: "",
        realname: "",
        code: "",
        username: "",
        nickname: "",
        phone: "",
        unit: "",
        profession: "",
        depart: "",
        memo: "",
      },
      modalVisible: false,
      searchParam: {
        searchField: "",
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
      },
      userList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "姓名",
        dataIndex: "realname",
        key: "realname",
        width: 100,
        fixed: "left",
      },
      {
        title: "工号",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
        width: 150,
      },
      {
        title: "昵称",
        dataIndex: "nickname",
        key: "nickname",
        width: 150,
      },
      {
        title: "状态",
        align: "center",
        dataIndex: "status",
        key: "status",
        width: 100,
        slots: {
          customRender: "status",
        },
      },
      {
        title: "公司",
        dataIndex: "unit",
        key: "unit",
        width: 200,
      },
      {
        title: "行业",
        dataIndex: "profession",
        key: "profession",
        width: 200,
      },
      {
        title: "部门",
        dataIndex: "depart",
        key: "depart",
        width: 200,
      },
      {
        title: "手机",
        dataIndex: "phone",
        key: "phone",
        width: 150,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        width: 150,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "备注",
        dataIndex: "memo",
        key: "memo",
        width: 300,
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 300,
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      if (state.searchValue) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      initUserList(state.searchParam);
    });
    const initUserList = (searchParam) => {
      state.loading = true;
      getUserListPage(searchParam).then((resp) => {
        if (resp) {
          state.userList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        searchField: state.field,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
      };
      initUserList(state.searchParam);
    };
    const onAdvanceSearch = () => {
      state.showSearchArea = !state.showSearchArea;
    };
    const onSearchChange = () => {
      //state.keyword = e.data;
      state.searchParam.searchValue = state.keyword;
      state.searchParam.searchField = state.field;
    };
    const onFieldChange = (value) => {
      state.field = value;
      state.searchParam.searchField = state.field;
    };
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchField = state.field;
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initUserList(state.searchParam);
    };
    const rowSelection = {
      onChange: (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys;
      },
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const doSetStatus = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将更新用户【" + record.username + "】的状态，是否继续？"
        ),
        onOk() {
          updateUserStatus({ uid: record.id, val: record.status ? 1 : 0 }).then(
            (resp) => {
              if (resp) {
                initUserList(state.searchParam);
              }
            }
          );
        },
        onCancel() {
          message.info("已取消用户状态更新操作！！！");
          initUserList(state.searchParam);
        },
      });
    };
    const doDeleteUser = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作讲永久删除【" + record.username + "】用户，是否继续？"
        ),
        onOk() {
          deleteUser(record.id).then((resp) => {
            if (resp) {
              initUserList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除用户操作！！！");
        },
      });
    };
    const doDeleteUsers = () => {
      if (state.selectedRowKeys.length > 0) {
        Modal.confirm({
          title: "温馨提示",
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确认",
          cancelText: "取消",
          content: createVNode(
            "div",
            { style: "color:red;" },
            "此操作讲永久删除所选择的用户，是否继续？"
          ),
          onOk() {
            deleteUsers({ ids: state.selectedRowKeys }).then((resp) => {
              if (resp) {
                initUserList(state.searchParam);
              }
            });
          },
          onCancel() {
            message.info("已取消删除用户操作！！！");
          },
        });
      } else {
        message.info("请先选择要删除的记录行！！！");
      }
    };
    const doAddUser = () => {
      handleModalVisible(true);
      state.isCreate = 0;
    };
    const doEditUser = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        realname: record.realname,
        code: record.code,
        username: record.username,
        nickname: record.nickname,
        phone: record.phone,
        unit: record.unit,
        profession: record.profession,
        depart: record.depart,
        memo: record.memo,
      };
      state.isCreate = 1;
    };
    const doDetailUser = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        realname: record.realname,
        code: record.code,
        username: record.username,
        nickname: record.nickname,
        phone: record.phone,
        unit: record.unit,
        profession: record.profession,
        depart: record.depart,
        memo: record.memo,
      };
      state.isCreate = 2;
    };
    const doExportData = () => {
      exportUsers();
    };
    const beforeUpload = () => {
      state.importBtnText = "正在导入";
      state.importBtnIcon = "loading";
      state.importDisabled = true;
    };
    const uploadChange = (info) => {
      console.log(info);
      if (info.file.status == "done") {
        state.importBtnText = "导入数据";
        state.importBtnIcon = "import1";
        state.importDisabled = false;
        initUserList(state.searchParam);
      }
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        initUserList(state.searchParam);
      }
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initUserList,
      onSearch,
      onAdvanceSearch,
      onSearchChange,
      onFieldChange,
      onChangePage,
      onShowSizeChange,
      onSelectChange,
      rowSelection,
      handleModalVisible,
      doSetStatus,
      doDeleteUser,
      doAddUser,
      doEditUser,
      doDetailUser,
      doDeleteUsers,
      doExportData,
      beforeUpload,
      uploadChange,
      saveCallBack,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.ant-btn {
  margin-right: 5px;
}
.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
.upload {
  display: inline-flex;
}
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
</style>